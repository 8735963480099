import React from "react";
import styled from "styled-components";
import {inject, observer} from "mobx-react";
import {withRouter} from "react-router-dom";
import {Collapse, List} from "antd";
import {CaretDownOutlined, CaretUpOutlined} from "@ant-design/icons";

import CpButton from "../../components/common/buttons/CpButton";
import {CpIconCompteur, CpIconCopro} from "../../components/common/CpIcon";
import CpTitle from "../../components/common/CpTitle";
import {DataTypeEnum} from "../../components/common/enums/DataTypeEnum";
import CpListHeader from "../../components/common/list/CpListHeader";
import CpListFilter from "../../components/common/list/CpListFilter";
import CpTypeCompteurFormat from "../../components/common/CpTypeCompteurFormat";
import CpProvider from "../../components/common/CpProvider";
import PropTypes from "prop-types";
import Moment from "moment";

import Documents from "../../components/document/Documents";
import CpApproachingDeadline
  from "../../components/copro/CpApproachingDeadline";
import CpCompteurDeadline from "../../components/compteur/CpCompteurDeadline";
import CpIconTarif from "../../components/common/CpIconTarif";
import variables from "../../stylesheets/tools/variables";
import {Pluralize} from "../../helpers";
import lang from "../../lang/fr";
import endpointEnum from "../../services/endpointEnum";

const {Panel} = Collapse;

const StyledCollapse = styled(Collapse)`
    .ant-collapse-header{
        padding: 0 !important;
    }
    .ant-list-item, .ant-collapse-item{
        border: 0;
    }
    .show-btn, .hide-btn{
        margin-top: 5px;
    }
    .ant-collapse-item{
        background: ${variables.white};
        &.ant-collapse-item-active{
            margin-bottom: 5px;
            .show-btn:not(.buttonModification){display: none}
        }
        &:not(.ant-collapse-item-active){
            .hide-btn{display: none}
        }
    }
`;

@inject("dictionary", "list")
@observer
class AgencyCoproList extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      activePanel: null,
      activeItem: null,
    };

    //instance type for list
    this.props.list.setType(DataTypeEnum.COPRO);
    this.onActivePanelChange = this.onActivePanelChange.bind(this);
  }

  componentDidMount() {
    const {managerId} = this.props;
    this.props.list.load(managerId);
  }

  onActivePanelChange(keys) {
    const {items, fillDependenciesById} = this.props.list;
    const activePanel = keys.length ? keys[keys.length - 1] : null;
    const item = items[activePanel];

    if (item) {
      //call fillDependenciesById from list store
      fillDependenciesById.bind(this.props.list)(item.id,
        ["documents", "compteurs"]);
    }

    this.setState({activePanel});
  }

  render() {
    const {activePanel} = this.state;
    const {items, inProgress, dependencyProgress} = this.props.list;

    if (inProgress) return null;

    function getTitle(cptItems) {
      return cptItems >= 2 ?
        "2+ documents" :
        (cptItems + (cptItems >= 2 ? " documents" : " document"));
    }
    return (
      <>
        <CpListHeader
          icon={<CpIconCopro/>}
          title={lang.copro.label.copropriete}
          count={items.length}
          filter={<CpListFilter fields={["nom", "reference", "adresse"]}/>}
          endPoint={endpointEnum.AGENCE_RECHERCHE()}
          values={{
            links: [],
          }}
        />

        <StyledCollapse
          bordered={false}
          activeKey={activePanel}
          onChange={(keys) => this.onActivePanelChange(keys)}
          expandIconPosition="right"
          destroyInactivePanel={true}
          expandIcon={() => <></>}
        >
          {items.map((i, index) => {
            return <Panel
              key={index}
              bordered={false}
              header={<List
                bordered={false}
                dataSource={[i]}
                renderItem={item => (
                  <List.Item
                    onClick={event => {
                      if (event.target.classList.contains(
                        "buttonModification") || event.target.closest(
                        ".ant-modal-wrap, .ant-select-dropdown, .ant-picker-dropdown")) {
                        event.stopPropagation();
                      }
                    }}
                  >
                    <List.Item.Meta
                      title={<CpTitle level={4}>{item.nom}</CpTitle>}
                      description={<>{item.nom || "-"}</>}
                    />
                    {item.syndicat ?
                    <List.Item.Meta
                      title={lang.copro.label.conseilSyndical}
                      description={item.syndicat || "-"}
                    /> : <List.Item.Meta/>}
                    {item.date_decision ?
                    <List.Item.Meta
                      title={<>{lang.copro.label.dateDecision}</>}
                      description={<>{item.date_decision ?
                        Moment(item.date_decision).format("DD/MM/YYYY") :
                        "-"}</>}
                    /> : <List.Item.Meta/>}
                    <List.Item.Meta
                      title={<>{lang.copro.label.compteurs}</>}
                      description={<CpApproachingDeadline data={item.echeances_compteurs} totalCompteurs={item.total_compteurs}/>}
                    />
                    <List.Item.Meta
                      description={<>
                        <CpButton block
                                  className={"show-btn"}>{lang.global.form.afficher} <CaretDownOutlined/></CpButton>
                        <CpButton block className={"hide-btn"}
                                  type={"principal"}>{lang.global.form.masquer} <CaretUpOutlined/></CpButton>
                      </>}
                      style={{flex: "0 0 100px"}}
                    />
                  </List.Item>
                )}
              />}>
              {!dependencyProgress ? [
                <List
                  key={"keyList"}
                  header={
                    <CpTitle level={3} style={{marginBottom: 0}}>
                      <CpIconCompteur/> {<Pluralize
                      count={i.compteurs ? i.compteurs.length : 0}
                      title={"Compteur"}/>}
                    </CpTitle>
                  }
                  bordered={false}
                  dataSource={i.compteurs}
                  renderItem={compteur => {
                    return <List.Item>
                      <List.Item.Meta
                        title={
                          <CpTitle level={4}>
                            <CpTypeCompteurFormat type={compteur.type}
                                                  style={{
                                                    fontSize: 22,
                                                    position: "relative",
                                                    top: 2,
                                                  }}/>
                            {compteur.number}
                          </CpTitle>}
                      />
                      <List.Item.Meta
                        title={<>Profil & conso</>}
                        description={<>
                          <CpIconTarif type={compteur.label}/>
                          {compteur.label}
                        </>}
                        style={{flex: "0 0 100px"}}
                      />
                      <List.Item.Meta
                        description={<CpProvider name={compteur.name}
                                                 logo={compteur.logo}
                                                 style={{height: 40}}/>}
                        style={{textAlign: "center"}}
                      />
                      <List.Item.Meta
                        title={<>Date d'échéance</>}
                        description={<CpCompteurDeadline
                          date_end={compteur.date_end}/>}
                      />
                      <List.Item.Meta
                        description={<>
                          <CpButton onClick={() => null} style={{
                            width: "100%",
                            marginBottom: 5,
                            cursor: "default",
                          }}>{getTitle(compteur.countDocuments)}</CpButton>
                        </>}
                        style={{flex: "0 0 125px"}}
                      />
                    </List.Item>;
                  }}
                />,
                <Documents key={i.id} copro={i}
                           dataSource={i.documents ? i.documents : []}/>,
              ] : null}
            </Panel>;
          })
          }

        </StyledCollapse>
      </>
    );
  }
}

AgencyCoproList.propTypes = {
  managerId: PropTypes.number.isRequired,
};

export default withRouter(AgencyCoproList);
