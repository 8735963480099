import React from "react";
import {withRouter} from "react-router-dom";
import {Layout, List, Input, Form} from "antd";
import {CaretDownOutlined} from "@ant-design/icons";

function MaestroCpListHeader({copro}) {
  const idsCompteurs = copro.factures.map((facture) => facture.id);
  const key = idsCompteurs[0];

  return (
    <Layout>
      <List.Item>
        <Form.Item name={["copros", key, "compteurs_ids"]} noStyle initialValue={idsCompteurs}>
          <Input type="hidden"/>
        </Form.Item>
        <Form.Item
          name={["copros", key, "nom_copro"]}
          label="Nom de la copro"
          style={{margin: "5px"}}
          onClick={event => event.stopPropagation()}
          initialValue={copro?.nom_copro}
        >
          <Input name={["copros", key, "nom_copro"]} value={copro?.nom_copro}/>
        </Form.Item>
        <Form.Item
          name={["copros", key, "numero_et_nom_voie"]}
          label="Adresse"
          initialValue={copro?.numero_et_nom_voie}
          rules={[{
            required: true,
            message: "Merci de fournir une adresse",
          }]}
          style={{margin: "5px"}}
          onClick={event => event.stopPropagation()}
        >
          <Input value={copro?.numero_et_nom_voie} name={["copros", key, "numero_et_nom_voie"]}/>
        </Form.Item>
        <Form.Item
          name={["copros", key, "code_postal"]}
          label="Code postal"
          initialValue={copro?.code_postal}
          rules={[{
            pattern: "^[0-9]*$",
            message: "Format invalide"
          },
            {
              required: true,
              message: "Merci de fournir un code postal",
            }]}
          style={{margin: "5px"}}
          onClick={event => event.stopPropagation()}
        >
          <Input value={copro?.code_postal} name={["copros", key, "code_postal"]}/>
        </Form.Item>
        <Form.Item
          name={["copros", key, "ville"]}
          label="Ville"
          initialValue={copro?.ville}
          rules={[
            {
              required: true,
              message: "Merci de fournir une ville",
            }]}
          style={{margin: "5px"}}
          onClick={event => event.stopPropagation()}
        >
          <Input value={copro?.ville} name={["copros", key, "ville"]}/>
        </Form.Item>
        <CaretDownOutlined style={{marginTop: "20px"}}/>
      </List.Item>
    </Layout>
  );
}

export default withRouter(MaestroCpListHeader);
