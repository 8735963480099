import React from "react";
import {inject, observer} from "mobx-react";
import PropTypes from "prop-types";
import {Switch} from "antd";
import {types} from "../../../constants";
import {withRouter} from "react-router-dom";

@inject("list")
@observer
class CpCompteurTypeFilter extends React.Component {

  state = {
    GAZ: true,
    ELEC: true,
  };

  constructor(props) {
    super(props);
    this.handleChangeELEC = this.handleChangeELEC.bind(this);
    this.handleChangeGAZ = this.handleChangeGAZ.bind(this);
  }

  componentDidMount() {
    const {list, field, history} = this.props;
    const {location} = history;

    const queryParams = new URLSearchParams(location.search);

    list.filterCompteurType.field = field;

    if (queryParams.has("gaz")) {
      list.filterCompteurType.values.push("GAZ");
      this.setState({ELEC: false});
      this.setState({GAZ: true});
    } else if (queryParams.has("elec")) {
      list.filterCompteurType.values.push("ELEC");
      this.setState({ELEC: true});
      this.setState({GAZ: false});
    } else {
      list.filterCompteurType.values.push("GAZ");
      list.filterCompteurType.values.push("ELEC");
    }
  }

  handleChangeELEC = value => {
    this.setState({ELEC: value});
    if (!value && !this.state.GAZ) {
      this.handleChangeGAZ(true);
    }

    const {list, history} = this.props;
    const {location} = history;
    const queryParams = new URLSearchParams(location.search);
    queryParams.delete("elec");
    queryParams.delete("gaz");

    if (value) {
      list.filterCompteurType.values.push("ELEC");
      if (!queryParams.has("elec") && !this.state.GAZ) {
        queryParams.append("elec", true);
      }
    } else {
      const index = list.filterCompteurType.values.indexOf("ELEC");
      list.filterCompteurType.values.splice(index, 1);
      queryParams.append("gaz", true);
    }

    history.replace({
      search: queryParams.toString()
    });
  };

  handleChangeGAZ = value => {

    this.setState({GAZ: value});
    if (!value && !this.state.ELEC) {
      this.handleChangeELEC(true);
    }

    const {list, history} = this.props;
    const {location} = history;
    const queryParams = new URLSearchParams(location.search);

    queryParams.delete("elec");
    queryParams.delete("gaz");

    if (value) {
      list.filterCompteurType.values.push("GAZ");
      if (!queryParams.has("gaz") && !this.state.ELEC) {
        queryParams.append("gaz", true);
      }
    } else {
      const index = list.filterCompteurType.values.indexOf("GAZ");
      list.filterCompteurType.values.splice(index, 1);
      queryParams.append("elec", true);
    }

    history.replace({
      search: queryParams.toString()
    });
  };

  render() {
    const mapping = {
      ELEC: this.handleChangeELEC,
      GAZ: this.handleChangeGAZ,
    };

    return (
      <>{Object.keys(types).map((type, i) =>
        <Switch
          style={{
            minWidth: 65,
            width: 65,
            margin: 5,
            marginRight: 15,
            marginLeft: 0,
          }}
          key={i}
          unCheckedChildren={type}
          checkedChildren={<><span style={{
            position: "absolute",
            right: "4px",
            top: "0",
            fontSize: "116%",
          }}>{types[type].icon}</span>{type}</>}
          checked={this.state[type]}
          onChange={mapping[type]}
        />
      )}</>
    );
  }
}

CpCompteurTypeFilter.propTypes = {
  field: PropTypes.string.isRequired,
};

export default withRouter(CpCompteurTypeFilter);
