import React from "react";
import PropTypes from "prop-types";
import {DatePicker} from "antd";
const {RangePicker} = DatePicker;
import {withRouter} from "react-router-dom";
import Moment from "moment";

class CpDateRangeFilter extends React.Component {

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    const {field, history, store} = this.props;
    const {location} = history;
    const queryParams = new URLSearchParams(location.search);

    store.filterDateRange.dateField = field;

    if (queryParams.has("date_debut")) {
      store.filterDateRange.dateStart = queryParams.get("date_debut");
    } else {
      store.filterDateRange.dateStart = null;
    }

    if (queryParams.has("date_fin")) {
      store.filterDateRange.dateEnd = queryParams.get("date_fin");
    } else {
      store.filterDateRange.dateEnd = null;
    }
  }

  handleChange = (date, moment) => {
    const {store, history} = this.props;
    const {location} = history;
    const queryParams = new URLSearchParams(location.search);

    queryParams.delete("date_debut");
    queryParams.delete("date_fin");
    if (date !== null) {
      queryParams.append("date_debut", moment[0].replace(/\//g, "-"));
      queryParams.append("date_fin", moment[1].replace(/\//g, "-"));
    }

    store.filterDateRange.dateStart = date === null ? null : moment[0];
    store.filterDateRange.dateEnd = date === null ? null : moment[1];

    history.push({
      search: queryParams.toString()
    })
  };

  render() {
    const {extraFooter, history} = this.props;
    const {location} = history;
    const queryParams = new URLSearchParams(location.search);

    return (
      <RangePicker
        format={"DD/MM/YYYY"}
        onChange={this.handleChange}
        renderExtraFooter={() => extraFooter}
        defaultValue={queryParams.has("date_debut") && queryParams.has("date_fin") ? [Moment(queryParams.get("date_debut"), "DD/MM/YYYY"), Moment(queryParams.get("date_fin"), "DD/MM/YYYY")] : null}
      />
    );
  }
}

CpDateRangeFilter.propTypes = {
  field: PropTypes.string.isRequired,
  store: PropTypes.object.isRequired,
  extraFooter: PropTypes.string,
};

export default withRouter(CpDateRangeFilter);
