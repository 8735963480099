import React from "react";
import {Link, withRouter} from "react-router-dom";
import {Layout, List, Select, Switch, Input} from "antd";
import {ApiOutlined, RiseOutlined} from "@ant-design/icons";
import lang from "../../lang/fr";
import Moment from "moment";

import {inject, observer} from "mobx-react";

import CpCompteurTendanceMarche
    from "../../components/compteur/CpCompteurTendanceMarche";
import CpListFilter from "../../components/common/list/CpListFilter";
import CpListHeader from "../../components/common/list/CpListHeader";
import {CpIconCompteur} from "../../components/common/CpIcon";
import CpTitle from "../../components/common/CpTitle";
import {
    DataTypeEnum,
    SwitchAgencyCompteurData,
} from "../../components/common/enums/DataTypeEnum";
import variables from "../../stylesheets/tools/variables";

import CpCompteurTypeFilter
    from "../../components/common/filters/CpCompteurTypeFilter";
import CpDeadlineFilter from "../../components/common/filters/CpDeadlineFilter";
import CpDateRangeFilter
    from "../../components/common/filters/CpDateRangeFilter";
import CpTypeCompteurFormat from "../../components/common/CpTypeCompteurFormat";
import CpIconTarif from "../../components/common/CpIconTarif";
import CpProvider from "../../components/common/CpProvider";
import CpCompteurDeadline from "../../components/compteur/CpCompteurDeadline";
import SwitchPE from "../../components/common/SwitchPE";
import BreadCrumbPE from "../../components/common/BreadCrumbPE";
import DataService from "../../services/dataService";

@inject("dictionary", "list")
@observer
class AgencyCompteurList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fournisseurs: [],
      list: [],
      editedItem: null,
      tendance: false,
      transac: false,
      filterFournisseur: null,
      filtreConsommation: {}
    };
    //instance type for detail
    this.props.list.setType(DataTypeEnum.COMPTEUR);

    this.onTrendCheck = this.onTrendCheck.bind(this);
    this.onTransactionCheck = this.onTransactionCheck.bind(this);
  }

  onTrendCheck () {
      const { list} = this.props;
      const isChecked = !this.state.tendance;
      this.setState({tendance: isChecked});
      list.filterCompteurTrend.value = isChecked;

  }
  onRechercheConso(valeur) {
      const {list} = this.props;
      this.setState({[this.state.filtreConsommation.montant]: valeur})
      list.filterConsommation.montant = valeur;
  }
  onChangementRechercheConso(value = null) {
      const {list} = this.props;
      this.setState({[this.state.filtreConsommation.type]: value})
      list.filterConsommation.type = value;
   }
   onRechercheParFournisseur (value = null) {
      const {list} = this.props;
      this.setState({filtreParFournisseur: value})
      list.filterFournisseur = value;
   }
   onTransactionCheck () {
      const { list} = this.props;
      const transactionExists = !this.state.transac;
      this.setState({transac: transactionExists});
      list.filterTransaction.value = transactionExists;
  }

  componentDidMount() {
    const {history, list} = this.props;
    this.setState({list: list})
    const {location} = history;
    const queryParams = new URLSearchParams(location.search);
    //Preload List items
    DataService.getCheckSession().then(({data}) => {
      if (data) {
        list.load();
      } else {
        if (window.location.pathname !== "/login") {
          history.push("/login");
        }
      }
    });

    if (queryParams.has("tendance")) {
      this.setState({tendance: true});
    }
    if (queryParams.has("transaction")) {
      this.setState({transac: true});
    }
  }

  render() {
    const {enums} = this.props.dictionary;
    const {items} = this.props.list;
    const nbCompteur = items ? items.length : 0;
    const filtreConso = this.state.filtreConsommation?.type;
    const fournisseurs = this.props.dictionary.providers;
    const listeNavigation = [
      {
        titre: `Compteurs`
      }
    ];
    return (
      <Layout>
        <BreadCrumbPE listeNavigation={listeNavigation}/>
        <SwitchPE
          marginTop
          handleClick={this.handleClick}
          selected={"compteur"}
          switchObject={SwitchAgencyCompteurData}
        />
        <CpTitle level={1}>{lang.compteur.mesCompteurs}</CpTitle>
        <List
          header={
            <CpListHeader
              visible={true}
              icon={<CpIconCompteur/>}
              title={lang.compteur.compteur}
              count={nbCompteur}
              filter={
                <div>
                  <CpCompteurTypeFilter field={"type"}/>
                    <div style={{alignItems: "center", display:"flex"}}>
                  <CpDeadlineFilter field={"date_echeance"} />
                  <CpDateRangeFilter
                    field={"date_echeance"}
                    store={this.props.list}
                    extraFooter={lang.compteur.filtre.filtrerParEcheance}
                  />
                  <CpListFilter fields={["pdl", "segment", "filtre_adresse_copro"]}/>
                  <div>
                  <Switch
                    className="switch-filter"
                    unCheckedChildren={lang.compteur.filtre.tendance}
                    checkedChildren={<>
                      <span className="span-switch-filter">
                      <RiseOutlined style={{color: "black"}}/>
                      </span>
                      {lang.compteur.filtre.tendance}</>}
                    checked={this.state.tendance}
                    onChange={this.onTrendCheck}
                  />
                  <Switch
                    className="switch-filter"
                    unCheckedChildren={lang.compteur.filtre.toutesTransactions}
                    checkedChildren={<>
                      <span className="span-switch-filter">
                         <ApiOutlined style={{color: "black"}}/>
                      </span>
                      {lang.compteur.filtre.sansTransactions}</>}
                    checked={this.state.transac}
                    onChange={this.onTransactionCheck}
                  />
                  </div>
                    </div>


                    <div style={{display: "flex", alignItems:"self-end"}}>

                        <Select
                            style={{width: "200px", marginRight:"20px"}}
                            placeholder="Fournisseur"
                            name="fournisseur"
                            allowClear={true}
                            optionFilterProp="children"
                            showSearch
                            onChange={(e) => this.onRechercheParFournisseur(e)}>
                            {fournisseurs.map((fournisseur, index) =>
                                <Select.Option
                                    key={index} value={fournisseur["id"]}>
                                    {fournisseur.nom}
                                    <img
                                        src={fournisseur.logo}
                                        alt={"logo"}
                                        height={"20px"}/>
                                </Select.Option>)}
                            <Select.Option
                                value="inconnu">
                                Inconnu
                            </Select.Option>
                            )) </Select>
                        <div style={{margin: "0px", verticalAlign: "center"}}>
                            <Select
                                style={{width: "150px"}}
                                placeholder="Type de consommation"
                                name="conso"
                                value={filtreConso}
                                allowClear={true}
                                onChange={(e) => this.onChangementRechercheConso(e)}>
                                   <Select.Option
                                        value='superieur'>
                                        Supérieur
                                    </Select.Option>
                                <Select.Option
                                        value='inferieur'>
                                        Inférieur
                                    </Select.Option>
                                <Select.Option
                                        value='egal'>
                                        Egal
                                    </Select.Option>
                            </Select>
                            <Input disabled={filtreConso === null} style={{width:"150px"}} name="consommation" placeholder="Consommation" onPressEnter={(e) => this.onRechercheConso(
                                e.target.value)}/>
                        </div>

                    </div>
                </div>
              }/>
          } bordered={false}
          dataSource={items}
          renderItem={item => (
            <Link to={"/manager/" + item.copropriete.gestionnaire + '/compteurs?filter=' + item.pdl}>
              <List.Item>
                <List.Item.Meta
                  title={
                    <CpTitle level={4}>
                      <CpTypeCompteurFormat type={item.type}
                                            style={{fontSize: 22}}/>
                      {item.pdl}
                    </CpTitle>}
                  description={<><span
                    style={{color: variables.waterGreenPrimary}}>{item.copropriete?.nom}</span> {item.copropriete?.adresse}</>}
                  style={{flex: "0 0 300px", marginRight: 10}}
                />
                <List.Item.Meta
                  title={item.type === "ELEC" ? lang.compteur.label.segment : lang.compteur.label.tarif}
                  description={<>
                    <CpIconTarif type={item.segment}/>
                    {item.segment}
                  </>}
                  style={{flex: "0 0 70px"}}
                />
                <List.Item.Meta
                  title={lang.compteur.label.consommation}
                  description={<>
                    {!item.consommation &&
                    <> - </>
                    }
                    {item.consommation &&
                    <>
                      {item.consommation.totale} {enums.powerTypeEnum ?
                      enums.powerTypeEnum[item.type] :
                      ""}
                    </>
                    }
                  </>}
                  style={{flex: "0 0 50px"}}
                />
                <List.Item.Meta
                  description={<CpProvider name={item.fournisseur?.nom} logo={item.fournisseur?.logo}
                                           style={{height: 40}}/>}
                  style={{textAlign: "center"}}
                />
                <List.Item.Meta
                  title={<>{lang.compteur.label.dateEcheance}</>}
                  description={<CpCompteurDeadline date_end={item.date_echeance}
                                                   deadline={item.deadline}/>}
                />
                {item.tendance_marche ?
                  <List.Item.Meta
                    title={<>{ lang.compteur.label.tendanceMarche}</>}
                    align={'center'}
                    description={<CpCompteurTendanceMarche prix={item.tendance_marche}/>}
                  />
                :
                <List.Item.Meta title={<></>} />
                }
                {!item.transaction_id ?
                  <List.Item.Meta
                    title={<>{lang.compteur.label.sansTransaction}</>}
                    align={'center'}
                  />
                  :
                  <List.Item.Meta align={"center"} title={<>{lang.compteur.label.transactionEnCours} {Moment(item.date_creation_transaction).format("DD/MM/YYYY")}</>}
                  />
                }
              </List.Item>
            </Link>
          )}
        />
      </Layout>
    );
  }
}

export default withRouter(AgencyCompteurList);
