
import React from "react";
import styled from "styled-components";
import {inject, observer} from "mobx-react";
import {Link, withRouter} from "react-router-dom";
import {Alert, Collapse, List, notification} from "antd";
import {FaExclamationTriangle} from "react-icons/fa";
import {
    CaretDownOutlined,
    CaretUpOutlined,
    PlusSquareOutlined,
} from "@ant-design/icons";

import CpModalEdit from "../../components/common/modal/CpModalEdit";
import CpButton from "../../components/common/buttons/CpButton";
import {
    CpIconCompteur,
    CpIconCopro,
    CpMaestro,
} from "../../components/common/CpIcon";
import CpTitle from "../../components/common/CpTitle";
import {DataTypeEnum} from "../../components/common/enums/DataTypeEnum";
import CpListHeader from "../../components/common/list/CpListHeader";
import CpListFilter from "../../components/common/list/CpListFilter";
import CpTypeCompteurFormat from "../../components/common/CpTypeCompteurFormat";
import CpProvider from "../../components/common/CpProvider";
import PropTypes from "prop-types";
import Moment from "moment";
import lang from "../../lang/fr";
import Documents from "../../components/document/Documents";
import CpApproachingDeadline
    from "../../components/copro/CpApproachingDeadline";
import CpCompteurDeadline from "../../components/compteur/CpCompteurDeadline";
import CpIconTarif from "../../components/common/CpIconTarif";
import variables from "../../stylesheets/tools/variables";
import FormCoproEdit from "../../components/common/forms/FormCoproEdit";
import CpModalDocument from "../../components/common/modal/CpModalDocument";
import {
    isNullOrUndefined,
    modifierDonneesCompteurLegacyPourPut, modifierDonneesCoproLegacyPourPut,
    Pluralize,
} from "../../helpers";
import CpDeleteButton from "../../components/common/buttons/CpDeleteButton";
import FormCoproMove from "../../components/common/forms/FormCoproMove";
import CpModalTransaction
    from "../../components/common/modal/CpModalTransaction";
import DataService from "../../services/dataService";
import endpointEnum from "../../services/endpointEnum";
import ModaleCreationCompteurSimple
    from "../../components/common/modal/ModaleCreationCompteurSimple";
import FormCompteur from "../../components/common/forms/FormCompteur";
import ModaleCommentaires
    from "../../components/common/modal/ModaleCommentaires";
import {formatageCommentaire} from "../../helpers/FormatagesDonnees";

const {Panel} = Collapse;

const StyledCollapse = styled(Collapse)`

  .ant-collapse-header {
    padding: 0 !important;
  }

  .ant-list-item, .ant-collapse-item {
    border: 0;
  }

  .show-btn, .hide-btn {
    margin-top: 5px;
  }

  .ant-collapse-item {
    background: ${variables.white};

    &.ant-collapse-item-active {
      margin-bottom: 5px;

      .show-btn:not(.buttonModification, .modal-btn-commentaire) {
        display: none
      }
    }

    &:not(.ant-collapse-item-active) {
      .hide-btn {
        display: none
      }
    }
  }
`;
@inject("dictionary", "list", "detail", "user")
@observer
class ManagerCoproList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activePanel: null,
            activeItem: null,
            editedItem: null,
            compteurs: null,
            copros: [],
        };

    //instance type for list
    this.props.list.setType(DataTypeEnum.COPRO);
    this.onActivePanelChange = this.onActivePanelChange.bind(this);
    this.onModalCloseCallback = this.onModalCloseCallback.bind(this);
    this.parentHandleChange = this.parentHandleChange.bind(this);
  }

  onCoproEditModalOpen(data, callback) {
    const activeItem = data;
    this.setState({activeItem}, () => callback());
  }

  onCompteurEditModalOpen(compteurId, callback) {
      let editedItem = {};
      return DataService.getById(DataTypeEnum.COMPTEUR, compteurId).
          then(({data}) => {
              editedItem = data;
              this.setState({editedItem}, () => callback());
          })
  }

  onEditCompteur(id, params){
      const {list} = this.props;
     DataService.edit(DataTypeEnum.COMPTEUR, id, params).then(() => {
          notification.success({
              message: "Les modifications ont bien été prises en compte",
          });
          list.fillDependenciesById(params.copropriete, ["documents", "compteurs"]);
     }).catch(() => {
          notification.error({
              className: "error-server",
              message: "Une erreur s'est produite, merci de réessayer.",
              duration: 3,
          });
      });
  }
  onEditCopro(id, params){
      DataService.edit(DataTypeEnum.COPRO, id, params).then(({data}) => {
          notification.success({
              message: "Les modifications ont bien été prises en compte",
          });
         this.props.list.updateItem(data);
      }).catch(() => {
          notification.error({
              className: "error-server",
              message: "Une erreur s'est produite, merci de réessayer.",
              duration: 3,
          });
      });
  }

  async onCompteurEditModalValid(compteur) {
      compteur.commentaire = this.state.editedItem.commentaire;
      const params = modifierDonneesCompteurLegacyPourPut(compteur);
      await this.onEditCompteur(compteur.id, params)
  }

    parentHandleChange(objetCommentaire, idObjet, typeObjet){
        if(isNullOrUndefined(objetCommentaire)) return;
        if(typeObjet === 'compteur') {
            const compteur = this.state.compteurs.find(compteur => compteur.id === idObjet);
            if (!compteur) return null;
            compteur.commentaire = objetCommentaire.commentaire
            const params = modifierDonneesCompteurLegacyPourPut(compteur);
            this.onEditCompteur(idObjet, params)
        }
        if(typeObjet === 'copro'){
            const copropriete = this.state.copros.find(copro => copro.id === idObjet);
            if (!copropriete) return null;
            copropriete.commentaire = objetCommentaire.commentaire;
            const params = modifierDonneesCoproLegacyPourPut(copropriete);
            this.onEditCopro(idObjet, params)
        }
    }

 async onCoproEditModalValid(copro) {
      const params = modifierDonneesCoproLegacyPourPut(copro)
      await this.onEditCopro(copro.id, params)
  }


  componentDidMount() {
    DataService.getCheckSession().then(({data}) => {
      if (data) {
        const {managerId} = this.props;
        this.props.list.load(managerId);
        const {user} = this.props.user;
        this.setState({userIri: "/api/users/" + user.id});
      } else {
        if (window.location.pathname !== "/login") {
          this.props.history.push("/login");
        }
      }
    });
      DataService.getAllObjectGestionnaire(DataTypeEnum.COMPTEUR, this.props.managerId).
          then(({data}) => {
              this.setState({compteurs: data});
          });
      DataService.getAll(DataTypeEnum.COPRO, this.props.managerId).
          then(({data}) => {
              this.setState({copros: data});
          })
  }

  onActivePanelChange(keys) {
    const {items, fillDependenciesById} = this.props.list;
    const activePanel = keys.length ? keys[keys.length - 1] : null;
    const item = items[activePanel];

    if (item) {
      //call fillDependenciesById from list store
      fillDependenciesById.bind(this.props.list)(item.id,
        ["documents", "compteurs"]);
    }

    this.setState({activePanel});
  }

  onModalCloseCallback(coproId) {
    const {list} = this.props;
    list.fillDependenciesById(coproId, ["documents", "compteurs"]);
  }

  onCoproMoveModalValid(object) {
    const {managerId} = this.props;
    return DataService.postDeplacerCopro({
      coproprieteSelectionnee: object.id,
      gestionnaireSelectionne: object.manager_id,
    }).then(() => {
      this.props.list.load(managerId);
    });
  }

  onDeleteCopro = (id, adresse) => {
    const {managerId} = this.props;
    const {history} = this.props;

    notification.success({
      message: `${lang.copro.message.coproMessage} "${adresse}" ${lang.copro.message.suppressionEffectue}`,
    });
    history.push(`/manager/${managerId}/copros`);
    this.props.list.removeItem(id);
  };

  openCompteurTabTraviata(compteur) {
    DataService.postCompteurTransaction(
      {compteur_id: compteur.id, transaction_id: compteur.transaction_id}).then(({data}) => {
      window.open(data);
    });
  }

  openCompteurTabHubspot = (compteur) => {
    DataService.postCompteurTransactionHubspot(
      {compteur_id: compteur.id, transaction_id: compteur.transaction_id}).then(({data}) => {
      window.open(data);
    });
  };

  render() {
    const {managerId, managerName, match} = this.props;
    const {activePanel, activeItem} = this.state;
    const {item} = this.props.detail;
    const {items, inProgress, dependencyProgress} = this.props.list;
    const {editedItem} = this.state;
    if (inProgress) return null;

    function getTitle(cptItems) {
      return cptItems >= 2 ?
             lang.compteur.label.plusDeuxTransactions :
             (cptItems + (cptItems >= 2 ? ` ${lang.compteur.label.documents}` : ` ${lang.compteur.label.document}`));
    }

    function ReferenceAdresseAffichage(props) {
      const reference = props.item.reference;
      const adresse = props.item.adresse;
      if (reference) {
        return <List.Item.Meta
          title={<CpTitle level={4}>{item.nom}</CpTitle>}
          description={<><span>ID de référence: {reference}</span><br/><span>{adresse || "-"}</span></>}
        />;
      }
      return <List.Item.Meta
        title={<CpTitle level={4}>{item.nom}</CpTitle>}
        description={<><span>{lang.copro.label.adresse || "-"}</span></>}
      />;
    }

    return (
      <>
        <div className="btn-pre-head">
          <Link to={`/gestionnaire/${managerId}/maestro`}>
            <CpButton
              cpsize="large"
              type="secondary"
              className="ant-btn show-btn"
              icon={<CpMaestro size={23}/>}
              style={{
                float: "right",
                minWidth: 110
              }}>Maestro
            </CpButton>
          </Link>
        </div>
        <CpListHeader
          visible={!!match.params.create}
          icon={<CpIconCopro/>}
          title={lang.copro.label.copropriete}
          count={items.length}
          filter={<>
            <CpListFilter fields={[
              "nom",
              "reference",
              "adresse"]}
            />
            <ModaleCreationCompteurSimple/>
          </>}
          endPoint={endpointEnum.COPRO_RECHERCHE()}
          form={(formElementData) => <FormCoproEdit
            cancelRedirect={`/manager/${managerId}/copros`} {...formElementData} />}
          onSubmit={(values, redirect) => {
            return redirect ?
                   this.props.history.push(
                     `/manager/${managerId}/compteurs/create/${values.id}`) :
                   this.props.list.updateItem(values);
          }}
          onSubmitRedirect={lang.compteur.label.ajouterCompteur}
          values={{
            gestionnaire: `${managerId}`,
            agence: `${item.agency.id}`,
          }}
        />

        <StyledCollapse
          bordered={false}
          activeKey={activePanel}
          onChange={(keys) => this.onActivePanelChange(keys)}
          expandIconPosition="right"
          destroyInactivePanel={true}
          expandIcon={() => <></>}
        >
          {items.map((i, index) => {
            return <Panel
              key={index}
              bordered={false}
              header={
                <List
                  bordered={false}
                  dataSource={[i]}
                  renderItem={item => (
                    <List.Item
                      onClick={event => {
                        if (event.target.classList.contains(
                          "buttonModification") || event.target.closest(
                          ".ant-modal-wrap, .ant-select-dropdown, .ant-picker-dropdown")) {
                          event.stopPropagation();
                        }
                      }}
                    >
                      <List.Item.Meta
                        title={<CpTitle level={4}>{item.nom}</CpTitle>}
                        description={<ReferenceAdresseAffichage item={item}/>}
                      />
                      <List.Item.Meta
                        title={lang.copro.label.conseilSyndical}
                        description={item.syndicat || "-"}
                      />
                      <List.Item.Meta
                        title={<>{lang.copro.label.dateDecision}</>}
                        description={<>{item.date_decision ?
                                        Moment(item.date_decision).format("DD/MM/YYYY") :
                                        "-"}</>}
                      />
                      <List.Item.Meta
                        title={<>{lang.compteur.compteur}</>}
                        description={<CpApproachingDeadline data={item.echeances_compteurs || {}}
                                                            totalCompteurs={item.total_compteurs}/>}
                      />
                      <List.Item.Meta
                        onClick={(e) => {
                          if (typeof e.target.className == "string" &&
                            !e.target.className.includes("letPropagate")) {
                            e.stopPropagation();
                          }
                        }}
                        description={<>
                          <CpModalEdit
                            title={
                              <div style={{display: "block", lineHeight: "18px"}}>
                                {lang.copro.label.deplacerCopro}
                                <Alert style={{marginTop: 15}}
                                       message={
                                         <span><FaExclamationTriangle/> <b>{lang.copro.label.tousCompteursDocsEtc}</b> {lang.copro.label.liesCopro}
                                           <b>{item.name}</b> {lang.copro.label.serontDeplacesVersGestionnaire}</span>}
                                       type="warning"/>
                              </div>
                            }
                            buttonProps={{
                              block: true,
                              style: {marginBottom: 5, width: "46%"},
                            }}
                            form={FormCoproMove}
                            values={{...item}}
                            onModalClose={this.modalClose}
                            mobxFunc={(object) => this.onCoproMoveModalValid(object)}
                          >
                            Déplacer
                          </CpModalEdit>
                          <CpDeleteButton
                            text={lang.copro.message.confirmationSuppression}
                            buttonProps={{
                              style: {
                                marginLeft: "2%",
                                marginBottom: 5,
                                width: "52%",
                              },
                            }}
                            entityIri={`/api/copros/${item.id}`}
                            title={lang.global.form.supprimer}
                            onDelete={() => this.onDeleteCopro(item.id, `${(item.nom ?
                                                                            item.nom + ", " :
                                                                            "")}${item.adresse}`)}/>
                          <CpModalEdit
                            title={
                              <div style={{display: "block", lineHeight: "18px"}}>
                                {lang.global.form.modification}
                                <h5 style={{textTransform: "upperCase"}}>{item.nom}</h5>
                              </div>
                            }
                            form={FormCoproEdit}
                            values={{...activeItem}}
                            buttonProps={{
                              className: "buttonModification show-btn",
                              block: true,
                            }}
                            onModalOpen={(callback) => this.onCoproEditModalOpen(
                              i, callback)}
                            mobxFunc={(object) => this.onCoproEditModalValid(
                              object)}
                            closeModalBeforeMobxFunc
                          >
                            {lang.global.form.modifier}
                          </CpModalEdit>
                            <ModaleCommentaires handleChange={this.parentHandleChange} commentaire={formatageCommentaire("copro", item)} origineDonnees="copro" idObjet={item.id} utilisateur={this.props.user}/>
                          <CpModalTransaction
                            item={i}
                            buttonProps={{
                              className: "buttonModification show-btn",
                              block: true,
                            }}
                            manager={managerId}
                            name={managerName}
                            size={"lg"}
                          >
                            <PlusSquareOutlined/>&nbps;{lang.copro.label.transaction}
                          </CpModalTransaction>
                          <CpButton
                            block
                            className={"show-btn letPropagate"}>
                            <span className={"letPropagate"}>{lang.global.form.afficher}</span>
                            <CaretDownOutlined/>
                          </CpButton>
                          <CpButton
                            block
                            className={"hide-btn letPropagate"}
                            type={"principal"}>
                            <span className={"letPropagate"}>{lang.global.form.masquer}</span>
                            <CaretUpOutlined/>
                          </CpButton>
                        </>}
                        style={{flex: "0 0 135px"}}
                      />
                    </List.Item>
                  )}
                />}>
              {!dependencyProgress ? [
                <List
                  key={"keyList"}
                  header={
                    <CpTitle level={3} style={{marginBottom: 0}}>
                      <CpIconCompteur/> {<Pluralize
                      count={i.compteurs ? i.compteurs.length : 0}
                      title={lang.compteur.compteur}/>}
                    </CpTitle>
                  }
                  bordered={false}
                  dataSource={i.compteurs}
                  renderItem={compteur => {
                    return <List.Item>
                      <List.Item.Meta
                        title={
                          <CpTitle level={4}>
                            <CpTypeCompteurFormat
                              type={compteur.type}
                              style={{
                                fontSize: 22,
                                position: "relative",
                                top: 2,
                              }}/>
                            {compteur.number}
                          </CpTitle>}
                      />
                      <List.Item.Meta
                        title={<>{lang.compteur.label.tarif}</>}
                        description={<>
                          <CpIconTarif type={compteur.label}/>
                          {compteur.label}
                        </>}
                        style={{flex: "0 0 70px"}}
                      />
                      <List.Item.Meta
                        description={
                          <CpProvider
                            name={compteur.name}
                            logo={compteur.logo}
                            style={{height: 40}}
                          />}
                        style={{textAlign: "center", flex: "0 0 140px"}}
                      />
                      <List.Item.Meta
                        title={<>{lang.compteur.label.consommations}</>}
                        description={<div>{compteur.consumption_total ? `${compteur.consumption_total} KWh` :
                                           "-"}</div>}
                        style={{flex: "0 0 135px"}}
                      />
                      <List.Item.Meta
                        title={<>{lang.compteur.label.dateEcheance}</>}
                        description={<CpCompteurDeadline
                          date_end={compteur.date_end}/>}
                      />
                      <List.Item.Meta
                        description={<>
                          <CpModalDocument
                            compteurId={compteur.id}
                            title={getTitle(compteur.documents?.length || "0")}
                            buttonProps={{
                              block: true,
                              style: {marginBottom: 5},
                            }}
                            onModalClose={() => this.onModalCloseCallback(i.id)}
                          />
                            <ModaleCommentaires handleChange={this.parentHandleChange} origineDonnees="compteur" commentaire={formatageCommentaire("compteur", compteur)} idObjet={compteur.id} utilisateur={this.props.user} />

                          {compteur.transaction_id ?
                           <>
                             <CpButton
                               block
                               style={{marginTop: "5px"}}
                               onClick={() => this.openCompteurTabTraviata(compteur)}
                             >
                               <span>{lang.copro.label.voirTraviata}</span>
                             </CpButton>
                             <CpButton
                               block
                               style={{marginTop: "5px"}}
                               onClick={() => this.openCompteurTabHubspot(compteur)}
                             >
                               <span>{lang.copro.label.voirHubspot}</span>
                             </CpButton>
                           </>
                                                   : ""
                          }
                          <CpModalEdit
                            buttonProps={{
                              block: true,
                              style: {marginTop: 5},
                            }}
                            form={(formElementData) => <FormCompteur
                              vueEndpoint={"managers"}
                              edition={true} {...formElementData} />}
                            onModalOpen={(callback) => this.onCompteurEditModalOpen(
                              compteur.id, callback)}
                            values={{...editedItem}}
                            mobxFunc={(editedItem) => this.onCompteurEditModalValid(
                              editedItem)}
                          />
                        </>}
                        style={{flex: "0 0 125px"}}
                      />
                    </List.Item>;
                  }}
                />,
                <Documents
                  key={i.id}
                  copro={i}
                  dataSource={i.documents ? i.documents : []}/>,
              ] : null}
            </Panel>;
          })}
        </StyledCollapse>
      </>
    );
  }
}

ManagerCoproList.propTypes = {
  managerId: PropTypes.number.isRequired,
};

export default withRouter(ManagerCoproList);
