import React from "react";
import {inject, observer} from "mobx-react";
import {Link, withRouter} from "react-router-dom";
import {List, notification} from "antd";
import {
  LineChartOutlined,
  PlusSquareOutlined,
  RedoOutlined,
} from "@ant-design/icons";
import {
  CpIconAdd,
  CpIconCompteur,
  CpMaestro,
} from "../../components/common/CpIcon";
import CpTitle from "../../components/common/CpTitle";
import {DataTypeEnum} from "../../components/common/enums/DataTypeEnum";
import CpListHeader from "../../components/common/list/CpListHeader";
import CpListFilter from "../../components/common/list/CpListFilter";
import CpTypeCompteurFormat from "../../components/common/CpTypeCompteurFormat";
import CpProvider from "../../components/common/CpProvider";
import CpModalDocument from "../../components/common/modal/CpModalDocument";
import CpDateRangeFilter
  from "../../components/common/filters/CpDateRangeFilter";
import CpCompteurDeadline from "../../components/compteur/CpCompteurDeadline";
import CpIconTarif from "../../components/common/CpIconTarif";
import CpButton from "../../components/common/buttons/CpButton";
import FormCompteur from "../../components/common/forms/FormCompteur";
import CpModalEdit from "../../components/common/modal/CpModalEdit";
import CpDeleteButton from "../../components/common/buttons/CpDeleteButton";
import variables from "../../stylesheets/tools/variables";
import CpCompteurTypeFilter
  from "../../components/common/filters/CpCompteurTypeFilter";
import CpModalTransaction
  from "../../components/common/modal/CpModalTransaction";
import CpDeadlineFilter from "../../components/common/filters/CpDeadlineFilter";
import DataService from "../../services/dataService";
import endpointEnum from "../../services/endpointEnum";
import lang from "../../lang/fr";
import ModaleCreationCompteurSimple from "../../components/common/modal/ModaleCreationCompteurSimple";
import ModaleCommentaires
    from "../../components/common/modal/ModaleCommentaires";
import {
    isNullOrUndefined,
    modifierDonneesCompteurLegacyPourPut,
} from "../../helpers";

@inject("dictionary", "list", "detail", "user")
@observer
class ManagerCompteurList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editedItem: null,
      compteurs: []
    };

    //instance type for list
    this.props.list.setType(DataTypeEnum.COMPTEUR);
    this.modalClose = this.modalClose.bind(this);
    this.parentHandleChange = this.parentHandleChange.bind(this);

  }

  componentDidMount() {
      DataService.getCheckSession().then(({data}) => {
          if (data) {
              this.loadList();
          } else {
              if (window.location.pathname !== "/login") {
                  this.props.history.push("/login");
              }
          }
      });
  }

  loadList() {
      const {managerId, agencyCompteur} = this.props;
      if (agencyCompteur) {
          this.props.list.getByAgencyId(managerId);
      } else {
          this.props.list.load(managerId);
      }
  }

  addToList(values) {
    this.props.list.updateItem(values);
  }

  modalClose() {
      this.loadList();
  }

  onCompteurEditModalOpen(compteurId, callback) {
      let editedItem = {};
      return DataService.getById(DataTypeEnum.COMPTEUR, compteurId).
          then(({data}) => {
              editedItem = data;
              this.setState({editedItem}, () => callback());
          })
  }

    onEditCompteur(id, params){
        DataService.edit(DataTypeEnum.COMPTEUR, id, params).then(() => {
            notification.success({
                message: "Les modifications ont bien été prises en compte",
            });
        }).catch(() => {
            notification.error({
                className: "error-server",
                message: "Une erreur s'est produite, merci de réessayer.",
                duration: 3,
            });
        }).finally(() => {
            this.modalClose()

        });
    }
 async onCompteurEditModalValid(compteur) {
     if(isNullOrUndefined(compteur)) return;
        compteur.commentaire = this.state.editedItem.commentaire;
        const params = modifierDonneesCompteurLegacyPourPut(compteur);
        await this.onEditCompteur(compteur.id, params)
  }

    parentHandleChange(objetCommentaire, idObjet){
      const compteurs = this.props.list.items;
      if(isNullOrUndefined(objetCommentaire) && isNullOrUndefined(compteurs)) return;
        const compteur = compteurs.find(compteur => compteur.id === idObjet);
      if (!compteur) return null;
        compteur.commentaire = objetCommentaire.commentaire
        const params = modifierDonneesCompteurLegacyPourPut(compteur);
        this.onEditCompteur(idObjet, params)
    }

  onLoadConso = (compteurId) => {
    DataService.getConsommation(compteurId).then(() => {
      this.modalClose();
    });
  };

  onDeleteCompteur = (pdl, id) => {
    const {managerId, history} = this.props;
    notification.success({
      message: `${lang.compteur.message.compteurMessage} "${pdl}" ${lang.compteur.message.suppressionEffectue}`,
    });
    history.push(this.props.agencyCompteur ? `/agency/${managerId}/compteurs` : `/manager/${managerId}/compteurs`);
    this.props.list.removeItem(id);
  };

  openTabTraviata(compteur) {
    DataService.postCompteurTransaction(
      {compteur_id: compteur.id, transaction_id: compteur.transaction_id}).then(({data}) => {
      window.open(data);
    });
  }

  openTabHubspot = (compteur) => {
    DataService.postCompteurTransactionHubspot(
      {compteur_id: compteur.id, transaction_id: compteur.transaction_id}).then(({data}) => {
      window.open(data);
    });
  };

  downloadContrat = (idCompteur) => {
    window.location.href = process.env.REACT_APP_SERVER_ROOT +
      `/api/compteur/${idCompteur}/contrat/telecharger`;
  };

  render() {
    const {enums} = this.props.dictionary;
    const {managerId, managerName, match, agencyCompteur} = this.props;
    const {items} = this.props.list;
    const {editedItem} = this.state;
    if (!items) return null;

    function getTitle(cptItems) {
      return cptItems.length > 1 ? `${cptItems.length} ${lang.compteur.label.documents}` : `${cptItems.length} ${lang.compteur.label.document}`;
    }

    return (
      <>
        {!agencyCompteur ?
         <div className="btn-pre-head">
           <Link to={`/gestionnaire/${managerId}/maestro`}>
             <CpButton
               cpsize="large"
               type="secondary"
               className="ant-btn show-btn"
               icon={<CpMaestro size={23}/>}
               style={{
                 float: "right",
                 minWidth: 110
               }}>Maestro
             </CpButton>
           </Link>
         </div> : ""
        }
        <List
          header={
            <CpListHeader
              visible={!!match.params.create}
              icon={<CpIconCompteur/>}
              title={lang.compteur.compteur}
              count={items.length}
              filter={
                <div style={{display: "flex", maxHeight: "30px"}}>
                  <CpCompteurTypeFilter field={"type"}/>
                  <CpDeadlineFilter field={"date_echeance"}/>
                  <CpDateRangeFilter
                    field={"date_echeance"}
                    store={this.props.list}
                    extraFooter={lang.compteur.filtre.filtrerParEcheance}
                  />
                  <CpListFilter
                    fields={["pdl", "segment", "filtre_nom_fournisseur", "filtre_adresse_copro"]}/>
                  <CpModalTransaction
                    item={{compteurs: items}}
                    buttonProps={{
                      icon: <CpIconAdd/>,
                      cpsize: "large",
                      type: "secondary",
                      className: "ant-btn show-btn",
                      block: true,
                      style: {width: "110px", minWidth: "110px"},
                    }}
                    manager={managerId}
                    name={managerName}
                    size={"lg"}>
                    <PlusSquareOutlined/>&nbps;{lang.compteur.label.transaction}
                  </CpModalTransaction>
                </div>
              }
              element={
                <ModaleCreationCompteurSimple/>
              }
              afficherBoutonFormulaire={false}
              form={(formElementData) => <FormCompteur vueEndpoint={agencyCompteur ? "agence" : "managers"}
                                                       cancelRedirect={`/manager/${managerId}/compteurs`}
                                                       isAddFromCopro={!!match.params.create} {...formElementData} />}
              endPoint={endpointEnum.COMPTEUR_RECHERCHE()}
              onSubmit={(values) => this.addToList(values)}
              values={{}}
            />
          }
          bordered={false}
          dataSource={items}
          renderItem={item => (
            <List.Item style={{cursor: "auto"}}>
              <List.Item.Meta
                title={
                  <CpTitle level={4}>
                    <CpTypeCompteurFormat type={item.type}
                                          style={{fontSize: 22}}/>
                    {item.pdl}
                  </CpTitle>}
                description={<><span
                  style={{color: variables.waterGreenPrimary}}>{item.copropriete?.nom}</span> {item.copropriete?.adresse}</>}
                style={{flex: "0 0 300px", marginRight: 10}}
              />
              <List.Item.Meta
                title={item.type === "ELEC" ? lang.compteur.label.segment : lang.compteur.label.tarif}
                description={<>
                  <CpIconTarif type={item.segment}/>
                  {item.segment}
                </>}
                style={{flex: "0 0 70px"}}
              />
              <List.Item.Meta
                title={lang.compteur.label.consommation}
                description={<>
                  {!item.consommation ?
                   <CpButton icon={<LineChartOutlined/>} style={{width: "93px"}}
                             onClick={(e) => {
                               this.onLoadConso(item.id);
                               e.preventDefault();
                             }}>{lang.compteur.label.recuperer}
                   </CpButton> :
                   <>
                     {item.consommation?.totale} {enums.powerTypeEnum ?
                                                  enums.powerTypeEnum[item.type] :
                                                  ""}
                     <CpButton icon={<RedoOutlined/>} style={{width: "93px"}}
                               onClick={(e) => {
                                 this.onLoadConso(item.id);
                                 e.preventDefault();
                               }}>{lang.compteur.label.rafraichir}
                     </CpButton>
                   </>}
                </>}
                style={{flex: "0 0 50px"}}
              />
              <List.Item.Meta
                description={
                  <CpProvider
                    name={item.fournisseur?.nom}
                    logo={item.fournisseur?.logo}
                    style={{height: 40}}/>}
                style={{textAlign: "center"}}
              />
              <List.Item.Meta
                title={<>{lang.compteur.label.dateEcheance}</>}
                description={<CpCompteurDeadline date_end={item.date_echeance}/>}
              />
              <List.Item.Meta
                description={<>
                  <CpModalEdit
                    buttonProps={{
                      block: true,
                      style: {marginBottom: 5, width: "46%"},
                    }}
                    form={(formElementData) => <FormCompteur vueEndpoint={agencyCompteur ? "agence" : "managers"}
                                                             edition={true} {...formElementData} />}
                    onModalOpen={(callback) => this.onCompteurEditModalOpen(
                      item.id, callback)}
                    values={{...editedItem}}
                    mobxFunc={(editedItem) => this.onCompteurEditModalValid(
                        editedItem)}
                  />
                  <CpDeleteButton
                    text={lang.compteur.message.compteurMessage.confirmationSuppression}
                    buttonProps={{
                      style: {
                        marginLeft: "2%",
                        marginBottom: 5,
                        width: "52%",
                      },
                    }}
                    entityIri={`/api/compteurs/${item.id}`}
                    onDelete={() => this.onDeleteCompteur(item.pdl, item.id)}
                    title={lang.global.form.supprimer}/>
                  <CpModalDocument
                    compteurId={item.id}
                    title={getTitle(item.documents)}
                    buttonProps={{block: true, style: {marginBottom: 5}}}
                    onModalClose={this.modalClose}/>
                    <ModaleCommentaires handleChange={this.parentHandleChange} origineDonnees="compteur" commentaire={!isNullOrUndefined(item.commentaire) ? item.commentaire : null} idObjet={item.id} utilisateur={this.props.user} />


                  {item.transaction_id ?
                   <>
                     <CpButton block style={{marginTop: "5px"}}
                               onClick={() => this.openTabTraviata(item)}>
                       <span>{lang.compteur.label.voirTraviata}</span>
                     </CpButton>
                     <CpButton block style={{marginTop: "5px"}}
                               onClick={() => this.openTabHubspot(item)}>
                       <span>{lang.compteur.label.voirHubspot}</span>
                     </CpButton>
                   </>
                                       : ""
                  }
                  {item.fichier_contrat !== null ?
                   <CpButton
                     block
                     style={{marginTop: "5px"}}
                     onClick={() => this.downloadContrat(item.id)}
                   >
                     <span>{lang.compteur.label.telechargerContrat}</span>
                   </CpButton>
                                                 : ""
                  }
                </>}
                style={{flex: "0 0 125px"}}
              />
            </List.Item>
          )}
        />
      </>
    );
  }
}

ManagerCompteurList.propTypes = {
  // managerId: PropTypes.number.isRequired,
};

export default withRouter(ManagerCompteurList);
