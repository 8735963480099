import React from "react";
import {Link, withRouter} from "react-router-dom";
import {Layout, List} from "antd";
import {inject, observer} from "mobx-react";
import lang from "../../lang/fr";
import CpListFilter from "../../components/common/list/CpListFilter";
import CpListHeader from "../../components/common/list/CpListHeader";
import FormAgency from "../../components/common/forms/FormAgency";
import {CpIconAgence} from "../../components/common/CpIcon";
import CpTitle from "../../components/common/CpTitle";
import {
  DataTypeEnum,
  SwitchAgencyCompteurData,
} from "../../components/common/enums/DataTypeEnum";
import CpCoproDeadline from "../../components/copro/CpCoproDeadline";

import styled from "styled-components";
import SwitchPE from "../../components/common/SwitchPE";
import BreadCrumbPE from "../../components/common/BreadCrumbPE";
import endpointEnum from "../../services/endpointEnum";
import DataService from "../../services/dataService";
import MarqueurPriorite from "../../components/common/MarqueurPriorite";

const StyledCoproEchues = styled.div`
  font-size: 85%;
  font-style: italic;
  font-weight: 550;
  width: 100%;
  color: #8c8c8c;
`;

@inject("dictionary", "list", "user")
@observer
class AgencyList extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      list: [],
    };
    //instance type for detail
    this.props.list.setType(DataTypeEnum.AGENCY);
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    //Preload List items
    DataService.getCheckSession().then(({data}) => {
      if (data) {
        this.props.list.load();
      } else {
        if (window.location.pathname !== "/login") {
          this.props.history.push("/login");
        }
        this.props.user.updateUser(null);
      }
    });
  }

  handleClick(e) {
    this.props.history.push(e.key);
  }

  render() {
    const {items} = this.props.list;
    const {isAdmin} = this.props.user;
    const listeNavigation = [
      {
        titre: `Agences`
      }
    ];
    return (
      <Layout>
        <BreadCrumbPE listeNavigation={listeNavigation}/>
        <SwitchPE
          marginTop
          handleClick={this.handleClick}
          selected={"agency"}
          switchObject={SwitchAgencyCompteurData}
          {...this.props}
        />
        <CpTitle level={1}>{lang.agence.titre}</CpTitle>
        <List
          header={
            <CpListHeader
              icon={<CpIconAgence/>}
              title={lang.agence.sousTitre}
              count={items.length}
              filter={
                <>
                  {(
                    isAdmin ?
                      <CpListFilter name={"filterCommerciaux"}
                                    placeholder={lang.agence.liste.filtreCommerciaux}
                                    fields={["filtreListeCommerciaux"]}
                                    optionList={items == null ?
                                      null :
                                      Array.from(new Set(
                                        items.map(c => c.filtreListeCommerciaux).
                                          join(",").
                                          split(",")))}/>
                      : ""
                  )}
                  <CpListFilter name={"filterNameDep"}
                                fields={["name", "filtreDepartement"]}/>
                </>
              }
              form={FormAgency}
              endPoint={endpointEnum.AGENCE_RECHERCHE()}
              values={{
                links: [],
              }}
              onSubmit={(values, redirect) => redirect ?
                this.props.history.push(`agency/${values.id}/gestionnaires/create`) :
                this.props.history.push(`agency/${values.id}/gestionnaires`)}
              onSubmitRedirect={lang.agence.liste.ajoutGestionnaire}
              onSubmitRedirectConstraintsFields={[
                "name",
                "siret",
                "siren"]} // disable button if those fields are null
            />
          }
          bordered={false}
          dataSource={items}
          renderItem={item => (
            <List.Item style={{ display: "flex", alignItems: "start"}}>
              <span style={{display:"block", paddingTop:"1.5px"}}>
                <MarqueurPriorite priorite={item.priorite} agencyId={item.id}/>
              </span>
              <Link to={`agency/${item.id}/gestionnaires`} style={{width:"100%", display: "flex"}}>
                <List.Item.Meta
                  style={{flexGrow: "12", paddingLeft: "16px"}}
                  title={
                    <CpTitle level={4}>
                      {item.name}{item.organismeRegroupement ?` (${item.organismeRegroupement})` :""}
                    </CpTitle>
                  }
                  description={
                    <>
                      {item?.department ? `${item?.department?.code} - ${item?.department?.name}` : ""}
                    </>
                  }
                />
                <List.Item.Meta
                  style={{flexGrow: "6"}}
                  title={<>{lang.agence.liste.gestionnaires}</>}
                  description={<>{item.countManagers}
                    <span style={{fontSize: "11px"}}> {(item.countManagers -
                      item.activeManagers) > 0
                      ?
                      `(${lang.global.dont} ${item.countManagers - item.activeManagers} ${lang.global.inactif}${
                      ((item.countManagers - item.activeManagers) > 1 ? "s" : "")})`
                      :
                      ""
                    }
                    </span>
                  </>}
                />
                <List.Item.Meta
                  style={{
                    flexGrow: "6",
                    lineHeight: "1.9",
                    verticalAlign: "top",
                  }}
                  title={<>{lang.agence.liste.coproEchoir}</>}
                  description={(
                    <div style={{position: "relative"}}>
                      <CpCoproDeadline total={item.countCopros}
                                       deadlines={item.deadlines ?
                                         item.deadlines :
                                         {}}/>
                      {item.coprosEchues < 1 ?
                        "" :
                        <StyledCoproEchues>{item.coprosEchues} {lang.agence.copro}{item.coprosEchues >
                        1 ? "s" : ""} {lang.agence.echue}{item.coprosEchues > 1 ?
                          "s" :
                          ""}</StyledCoproEchues>}
                    </div>
                  )}
                />
              </Link>
            </List.Item>
          )}
        />
      </Layout>
    );
  }
}

export default withRouter(AgencyList);
