import React from "react";
import {inject, observer} from "mobx-react";
import {withRouter} from "react-router-dom";
import {Col, Layout, notification, Row} from "antd";
import CpModalEdit from "../../components/common/modal/CpModalEdit";
import {CpIconMail, CpIconPhone} from "../../components/common/CpIcon";
import variables from "../../stylesheets/tools/variables";
import CpTitle from "../../components/common/CpTitle";
import {
  DataTypeEnum,
  } from "../../components/common/enums/DataTypeEnum";
import ManagerCompteurList from "./ManagerCompteurList";
import ManagerCoproList from "./ManagerCoproList";
import CpHeadCapsule from "../../components/common/CpHeadCapsule";
import FormManager from "../../components/common/forms/FormManager";
import CpDeleteButton from "../../components/common/buttons/CpDeleteButton";
import SwitchPE from "../../components/common/SwitchPE";
import {Capitalize} from "../../helpers";
import BreadCrumbPE from "../../components/common/BreadCrumbPE";
import DataService from "../../services/dataService";
import lang from "../../lang/fr";


@inject("dictionary", "detail", "list")
@observer
class ManagerDetail extends React.Component {

  constructor(props) {
    super(props);

    const {list, detail} = this.props;

    this.state = {
      current: props.match.params.tab,
      managerId: 0,
      isLoading: false
    };

    //instance type for detail
    detail.setType(DataTypeEnum.MANAGER);

    //reset type of list
    list.setType(null);

    //reset COPRO/COMPTEUR filters
    list.resetFilter(DataTypeEnum.COPRO);
    list.resetFilter(DataTypeEnum.COMPTEUR);
  }

  componentDidMount() {
    this.setState({isLoading: true});
    DataService.getCheckSession().then(({data}) => {
      if (data) {
        const {params: {id}} = this.props.match;

        this.loadManagerById(id);
      } else {
        if (window.location.pathname !== "/login") {
          this.props.history.push("/login");
        }
        this.props.user.updateUser(null);
      }
      this.setState({isLoading: false});
    });
  }

  componentDidUpdate() {
    const {params: {id, tab}} = this.props.match;
    const {search} = this.props.location;
    const {list} = this.props;
    const {current, managerId, isLoading} = this.state;

    const params = new URLSearchParams(search);
    const filter = params.get("filter");

    //wait list.type correctly setted to store from child components of ManagerDetail
    if (filter && list.type) {
      list.setFilters([filter], "default");
    }

    //load manager
    if (managerId !== id && !isLoading) {
      this.loadManagerById(id);
    }

    // if due to global search tab change
    if (current !== tab) {
      this.setState({current: tab});
    }
  }

  /**
   * Load manager from id
   * @param {number} id
   * */
  loadManagerById = (id) => {
    this.props.detail.reset().load(id);
    this.setState({managerId: id});
  };

  handleClick = e => {
    if (e.key === "copros") {
      this.props.list.resetFilter(DataTypeEnum.COPRO);
    } else if (e.key === "compteurs") {
      this.props.list.resetFilter(DataTypeEnum.COMPTEUR);
    }
    this.props.history.push(e.key);

    this.setState({
      current: e.key,
    });
  };

  onDeleteManager = () => {

    const {detail: {item}} = this.props;
    const {history} = this.props;

    notification.success({
      message: `${lang.gestionnaire.message.gestionnaireMessage} "${item.qualifiedName}" ${lang.gestionnaire.message.suppressionEffectue}`,
    });

    history.push(`/agency/${item.agency.id}/gestionnaires`);
  };

  render() {
    const {current} = this.state;
    const {item, update} = this.props.detail;

    if (!item || item.agency === undefined) return null;

    const titreListe = () => {
    if(this.state.current === "copros"){
     return "Copropriétés"
    } else {
      return  Capitalize(this.state.current)
    }
    };

    const listeNavigation = [
      {
        titre: item.agency.name,
        href: `/agency/${item.agency.id}/gestionnaires`
      },
      {
        titre: item.qualifiedName,
        href: `/manager/${item.id}/copros`
      },
      {
        titre: titreListe()
      },
    ];
    return (
      <Layout>
        {/* Main Informations & Comments */}
        <BreadCrumbPE listeNavigation={listeNavigation}/>
        <Row align="middle">
          <CpTitle level={1}> {item.qualifiedName}
          </CpTitle>
        </Row>

        {/* GESTIONNAIRE INFO */}
        <CpHeadCapsule>
          <Row>
            <Col span={24}>
              <Row align={"middle"}>
                <Col span={18}>
                  <div style={{
                    display: "inline-block",
                    verticalAlign: "middle",
                    marginRight: "8px",
                  }}>
                    {item.phone ? <CpIconPhone/> : null}
                    {item.email ?
                      <a style={{marginLeft: 5, color: "inherit"}}
                         href={"mailto:" + item.email}><CpIconMail/></a> :
                      null}
                  </div>
                  <div style={{
                    marginLeft: 10,
                    display: "inline-block",
                    verticalAlign: "middle",
                  }}>
                    <a style={{color: "inherit"}}
                       href={"mailto:" + item.email}>{item.email}</a><br/>
                    {item.phone}
                    {item.mobile && item.phone ?
                      <span style={{
                        color: variables.waterGreenPrimary,
                        marginRight: 5,
                        marginLeft: 5,
                      }}>//</span> :
                      ""}
                    {item.mobile}
                  </div>
                </Col>

                <Col span={6}>
                  <div style={{
                    display: "block",
                    marginRight: "10px",
                    textAlign: "right",
                  }}>
                    <CpModalEdit buttonProps={{style: {marginRight: "10px"}}}
                                 form={FormManager}
                                 values={{...item, agency:item.agency.id}}
                                 mobxFunc={update.bind(this.props.detail)}
                                 onSubmit={() => this.props.detail.load(item.id)}/>
                    {/*<CpModalEdit buttonProps={{style: {marginRight: "10px"}}}*/}
                    {/*             form={FormManager}*/}
                    {/*             values={item}*/}
                    {/*             useId={item.id}*/}
                    {/*             endPoint="/api/gestionnaires"*/}
                    {/*             onSubmit={() => this.props.detail.load(item.id)}*/}
                    {/*             />*/}
                    <CpDeleteButton
                      text={lang.gestionnaire.message.confirmationSuppression}
                      buttonProps={{size: "small", style: {marginLeft: 5}}}
                      entityIri={`/api/gestionnaires/${item.id}`}
                      onDelete={() => this.onDeleteManager()}/>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </CpHeadCapsule>

        {/* VUE COMPTEURS/COPROS */}
        <SwitchPE
          handleClick={this.handleClick}
          selected={this.state.current}
          switchObject={[
            {
              key: "copros",
              path: `/manager/${item.id}/copros`,
              pathName: lang.global.switch.copropriete,
            },
            {
              key: "compteurs",
              path: `/manager/${item.id}/compteurs`,
              pathName: lang.global.switch.compteurs,
            },
          ]}
          marginBottom
        />
        {/* LIST COMPTEURS/COPROS */}
        {current === "compteurs" ?
          <ManagerCompteurList managerId={item.id}
                               managerName={item.qualifiedName}/> :
          <ManagerCoproList managerId={item.id}
                            managerName={item.qualifiedName}/>}
      </Layout>
    );
  }
}

export default withRouter(ManagerDetail);
