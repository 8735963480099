import React from "react";
import styled from "styled-components";
import {inject, observer} from "mobx-react";
import {withRouter} from "react-router-dom";
import {Col, Layout, notification, Row, Tooltip} from "antd";
import CpTitle from "../../components/common/CpTitle";
import CpModalEdit from "../../components/common/modal/CpModalEdit";
import FormAgency from "../../components/common/forms/FormAgency";
import {DataTypeEnum} from "../../components/common/enums/DataTypeEnum";
import CpUploadButton from "../../components/common/buttons/CpUploadButton";
import CpModalAccess from "../../components/common/modal/CpModalAccess";
import CpHeadCapsule from "../../components/common/CpHeadCapsule";
import CpText from "../../components/common/CpText";
import variables from "../../stylesheets/tools/variables";
import CpDeleteButton from "../../components/common/buttons/CpDeleteButton";
import CpExportButton from "../../components/common/buttons/CpExportButton";
import SwitchPE from "../../components/common/SwitchPE";
import GestionnaireList from "../../components/gestionnaire/GestionnaireList"
import ManagerCompteurList from "./ManagerCompteurList";
import lang from "../../lang/fr";
import MarqueurPriorite from "../../components/common/MarqueurPriorite"

import BreadCrumbPE from "../../components/common/BreadCrumbPE";
import {Capitalize} from "../../helpers";
import DataService from "../../services/dataService";
import endpointEnum from "../../services/endpointEnum";

const StyledColButtonHeadCapsule = styled(Col)`
  text-align: right;

  .ant-btn {
    margin-left: 10px;
  }
`;

@inject("dictionary", "detail", "user")
@observer
class AgencyDetail extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      compteurs: [],
      deadlines: [],
      currentTab: "gestionnaires",
    };

    //instance type for detail
    this.props.detail.setType(DataTypeEnum.AGENCY);
    this.onDeleteAgency = this.onDeleteAgency.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    DataService.getCheckSession().then(({data}) => {
      if (data) {
        const id = this.props.match.params.id;
        const tab = this.props.match.params.tab;
        this.setState({currentTab: tab})
        this.props.detail.reset().load(id);
        this.getCompteurs(id)
      } else {
        if (window.location.pathname !== "/login") {
          this.props.history.push("/login");
        }
        this.props.user.updateUser(null);
      }
    });
  }

  onEndUpload() {
    const id = this.props.match.params.id;
    this.props.detail.reset().load(id);
  }

  onDeleteAgency() {
    const {item} = this.props.detail;
    const {history} = this.props;

    notification.success({
      message: `${lang.agence.message.agenceMessage} "${item.name}" ${lang.agence.message.suppressionEffectue}`,
    });

    history.push("/agency");
  }

  redirectHubspot(id) {
    DataService.getAgencyHubspotId(id).then((res) => {
      window.open(res.data, "_blank").focus();
    });
  }
  handleClick(e) {
    this.setState({
      currentTab: e.key,
    });
  }

  getCompteurs(id) {
    DataService.getByAgencyId(DataTypeEnum.COMPTEUR, id).then((response) => {
     this.setState({compteurs: response.data});
    })
  }
  render() {
    const compteurs = this.state.compteurs;
    const {isAdmin} = this.props.user;
    const {item, inProgress, update} = this.props.detail;

    if  (!item || item.id === undefined) return null;

    const titreListe = Capitalize(this.state.currentTab);
    const {id} = this.props.match.params;

    const listeNavigation = [
      {
        titre: item.name,
        href: `/agency/${id}/${this.state.currentTab}`
      },
      {
        titre: `${titreListe}`
      }
    ];


    if (inProgress) return null;

    return (
      <Layout>
        {/* Main Informations & Comments */}
        <BreadCrumbPE listeNavigation={listeNavigation}/>
        <Row align="middle">
          <CpTitle level={1}>
            <span style={{verticalAlign: "middle"}}>
              {item.name}{item.organismeRegroupement ?
              `(${item.organismeRegroupement})` :
              ""}
            </span>
            <MarqueurPriorite priorite={item.priorite} agencyId={id}/>
            <Tooltip placement="bottomRight"
                     title={lang.global.detail.tooltipVoirEntrepriseHS}>
              <a target="_blank" rel="noopener noreferrer"
                 onClick={() => this.redirectHubspot(item.id)}>
                <svg width="28px" height="28px" viewBox="0 0 70 70" style={{
                  marginLeft: "20px",
                  verticalAlign: "middle",
                  cursor: "pointer",
                }}>
                  <g id="icon/hubspot" stroke="none" fill="none">
                    <path
                      d="M56.7164087,27.9817391 C54.7816873,26.433962 52.2665495,25.4666013 49.7514117,25.079657 L49.7514117,18.11466 C51.6861331,17.3407715 52.846966,15.4060501 52.846966,13.4713287 L52.846966,13.2778566 C52.846966,10.3757745 50.5253003,8.05410882 47.6232182,8.05410882 L47.4297461,8.05410882 C44.527664,8.05410882 42.2059983,10.3757745 42.2059983,13.2778566 L42.2059983,13.4713287 C42.2059983,15.5995222 43.3668312,17.3407715 45.3015526,18.11466 L45.3015526,24.8861849 C42.7864148,25.2731291 40.271277,26.433962 38.1430834,27.9817391 L19.376286,13.4713287 C19.5697581,13.0843844 19.5697581,12.503968 19.5697581,11.9235516 C19.5697581,8.82799737 17.0546203,6.11938743 13.765594,6.11938743 C10.4765676,6.11938743 7.76795769,8.82799737 7.76795769,12.1170237 C7.76795769,15.4060501 10.4765676,18.11466 13.765594,18.11466 C13.765594,18.11466 13.765594,18.11466 13.765594,18.11466 C14.7329547,18.11466 15.7003154,17.9211879 16.6676761,17.3407715 L35.0475292,31.6577097 C31.5650307,36.6879853 31.7585029,43.4595102 35.2410014,48.4897858 L29.6303093,54.1004778 C29.2433651,53.9070056 28.6629486,53.9070056 28.2760044,53.9070056 C25.5673944,53.9070056 23.4392009,56.0351992 23.4392009,58.7438091 C23.4392009,61.452419 25.5673944,63.5806126 28.2760044,63.5806126 C30.9846143,63.5806126 33.1128078,61.452419 33.1128078,58.7438091 C33.1128078,58.3568648 33.1128078,57.7764484 32.9193357,57.3895041 L38.5300277,51.7788121 C45.1080804,56.8090877 54.3947431,55.6482549 59.4250187,49.0702022 C64.4552943,42.2986773 63.2944614,33.0120147 56.7164087,27.9817391 Z M47.4297461,47.5224251 L47.4297461,47.5224251 C43.173359,47.7158972 39.6908605,44.2333987 39.4973884,39.9770117 C39.3039163,35.7206246 42.7864148,32.2381261 47.0428018,32.044654 C47.2362739,32.044654 47.2362739,32.044654 47.4297461,32.044654 C51.6861331,32.2381261 54.9751595,35.7206246 54.7816873,39.9770117 C54.7816873,44.0399266 51.492661,47.3289529 47.4297461,47.5224251 Z"
                      id="Shape" fill="#ff7a59"></path>
                  </g>
                </svg>
              </a>
            </Tooltip>
          </CpTitle>
        </Row>
        <CpHeadCapsule style={{
          borderColor: variables.grey4,
          width: "80%",
          margin: "0 auto",
        }}>
          <Row align={"top"}>
            <Col span={14}>
              <CpTitle level={5}>{lang.agence.formulaire.label.adressePostale}</CpTitle>
              <CpText>{item.qualifiedAddress}</CpText>
            </Col>
            <Col span={5}>
              <CpTitle level={5}>{lang.agence.formulaire.label.siret}</CpTitle>
              <div>{item.siret || <i>-</i>}</div>
            </Col>
            <Col span={5}>
              <CpTitle level={5}>{lang.agence.formulaire.label.siren}</CpTitle>
              <div>{item.siren || <i>-</i>}</div>
            </Col>
          </Row>
        </CpHeadCapsule>

        <CpHeadCapsule style={{
          borderColor: variables.grey4,
          width: "80%",
          margin: "3px auto",
        }}>
          <Row align={"top"}>
            <StyledColButtonHeadCapsule span={18} style={{alignSelf: "center"}}>
              <CpModalEdit
                form={FormAgency}
                mobxFunc={update.bind(this.props.detail)}
                values={item}
                size="l" title={lang.global.form.modifier}/>
              {isAdmin ? <CpExportButton
                  endPoint={endpointEnum.EXPORT()}
                  values={item}
                  onEndUpload={() => this.onEndUpload()}/>
                : null}
              <CpUploadButton
                templateUrl="import-template"
                endPoint={endpointEnum.IMPORT()}
                values={item}
                onEndUpload={() => this.onEndUpload()}/>

              {isAdmin ? <CpUploadButton
                  templateUrl="import-update-template"
                  endPoint={endpointEnum.IMPORT_UPDATE()}
                  values={item}
                  onEndUpload={() => this.onEndUpload()}
                  btnTitle={lang.global.form.btnUpdate}/>
                : null}

              <CpModalAccess
                agencyId={item.id}
                agencyAccountAccess={item?.agencyAccountAccess ?
                  item.agencyAccountAccess :
                  null}
              >
                {lang.agence.formulaire.titreInput.envoiAcces}
              </CpModalAccess>

              {isAdmin ? <CpDeleteButton
                  title= {lang.global.form.supprimer}
                  text={lang.agence.message.confirmationSuppression}
                  onDelete={() => this.onDeleteAgency()}
                  entityIri={`/api/agences/${item.id}`}/>
                : null}
            </StyledColButtonHeadCapsule>
          </Row>
        </CpHeadCapsule>
        <Row>
          <SwitchPE
            handleClick={this.handleClick}
            selected={this.state.currentTab}
            switchObject={[
              {
                key: "gestionnaires",
                path: `/agency/${item.id}/gestionnaires`,
                pathName: lang.global.switch.gestionnaires,
              },
              {
                key: "compteurs",
                path: `/agency/${item.id}/compteurs`,
                pathName: lang.global.switch.compteurs,
              },
            ]}
            marginTop
            marginBottom
          />
        </Row>

        {
          this.state.currentTab === "gestionnaires" ?
            <GestionnaireList
              compteurs={compteurs}
              deadlines={this.state.deadlines}
              {...this.props}
            />
            :
            <ManagerCompteurList
              compteurs={compteurs}
              agencyCompteur
              managerId={item.id}
              managerName={item.name}
            />
        }
      </Layout>
    );
  }
}

export default withRouter(AgencyDetail);

