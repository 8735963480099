import React from "react";
import {DatePicker, Input, Select} from "antd";
import {inject, observer} from "mobx-react";
import CpEditForm from "../CpEditForm";
import "react-phone-number-input/style.css";
import CpAjaxSelectAdd from "../CpAjaxSelectAdd";
import DataService from "../../../services/dataService";
import endpointEnum from "../../../services/endpointEnum";
import moment from "moment";

@inject("dictionary")
@observer
class FormCoproEdit extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      cities: [],
      values: props.values,
    };

    this.handleSearchPostalCode = this.handleSearchPostalCode.bind(this);
  }

  componentDidMount() {
    const {isCreationMode, values} = this.state;

    //preload cities data for select
    if (!isCreationMode && values.code_postal) {
      this.handleSearchPostalCode(values.code_postal, true);
    }
  }

  /**
   * On typing in the select, perform a search
   * @param {string|number} postalCode
   * @param {boolean} updateOnlyCities
   */
  handleSearchPostalCode = (postalCode, updateOnlyCities = false) => {
    if (postalCode.length === 5) {
      DataService.getVilles(postalCode).then(({data}) => {
        if (updateOnlyCities) {
          this.setState({
            cities: data["hydra:member"],
          });
        } else {
          this.setState({
            cities: data["hydra:member"],
            values: {
              ...this.state.values,
              code_postal: postalCode,
              ville: "",
            }
          });
        }
      });
    }
  };

  onSelectCity = (city) => {
    if (city === null)
      return;

    this.setState({
      values: {
        ...this.state.values,
        ville: city.name
      }
    });
  };

  onChange = (key, value) => {
    this.setState({
      values: {
        ...this.state.values,
        [key]: value
      },
    });
  };


  render() {
    const {formRef, setRequiredFields, onFieldsChange} = this.props;
    const {values, cities} = this.state;
    const fields = [
      {
        label: "Nom",
        key: "nom",
        flex: "80%",
        input: <Input
          placeholder="[SDC-Nom Copropriété]"
          onChange={(event) => this.onChange(event.target.id, event.target.value)}
        />,
      }, {
        label: "Ref interne agence",
        key: "reference",
        flex: "20%",
        input: <Input
          placeholder="Ref interne agence"
          onChange={(event) => this.onChange(event.target.id, event.target.value)}
        />,
        options: {
          rules: [
            {
              required: !!values.id,
              message: "Champ obligatoire",
            },
          ],
        },
      }, {
        label: "Rue",
        key: "rue",
        flex: "45%",
        input: <Input
          placeholder="Adresse - Rue"
          onChange={(event) => this.onChange(event.target.id, event.target.value)}
        />,
        options: {
          rules: [
            {
              required: true,
              message: "La rue est obligatoire",
            }],
        },
      }, {
        label: "Code Postal",
        key: "code_postal",
        flex: "20%",
        input: <Input
          onChange={(event) => this.handleSearchPostalCode(event.target.value)}
          placeholder="Code Postal"/>,
        options: {
          rules: [
            {pattern: "^[0-9]*$", message: "Format invalide"},
            {required: true, message: "Champs obligatoire"},
          ]
        },
      }, {
        label: "Ville",
        key: "ville",
        flex: "35%",
        clone: true,
        input: <CpAjaxSelectAdd
          endPoint={endpointEnum.VILLE_RECHERCHE()}
          labelField="name"
          cities={cities}
          onSelectCity={this.onSelectCity}
          selectedValue={values.ville}
          placeholder="Adresse - Ville"
        />,
        options: {
          rules: [
            {
              required: true,
              message: "La ville est obligatoire",
            }],
        },
      }, {
        label: "Date décision",
        type: "date",
        key: "date_decision",
        flex: "45%",
        input: <DatePicker
          placeholder="Date décision"
          format={"DD/MM/YYYY"}
          onChange={(dateValue) => this.setState({
            values: {
              ...this.state.values,
              date_decision: dateValue ? moment(dateValue).format('YYYY-MM-DD HH:mm:ss') : null
            }
          })
          }
        />,
      }, {
        label: "Type de décision",
        key: "type_decision",
        flex: "55%",
        input: <Select
          placeholder="Sélectionner le type de décision..."
          onChange={(e) => this.setState({
            values: {
              ...this.state.values,
              type_decision: e
            }
          })
          }
        >
          <Select.Option value="CS">CS (Conseil Syndical)</Select.Option>
          <Select.Option value="AG">AG (Assemblée Générale)</Select.Option>
          <Select.Option value="GS">GS (Gestionnaire)</Select.Option>
        </Select>,
      }, {
        label: "Contact Conseil Syndical",
        type: "separator",
        key: "conseilSyndicalSeparator",
      }, {
        label: "Nom",
        key: "conseil_syndical_nom",
        flex: "50%",
        input: <Input
          placeholder="Nom"
          onChange={(event) => this.onChange(event.target.id, event.target.value)}
        />,
      }, {
        label: "Prenom",
        key: "conseil_syndical_prenom",
        flex: "50%",
        input: <Input
          placeholder="Prenom"
          onChange={(event) => this.onChange(event.target.id, event.target.value)}
        />,
      }, {
        label: "Téléphone",
        key: "conseil_syndical_telephone",
        flex: "25%",
        input: <Input
          type="tel"
          placeholder="Téléphone"
          onChange={(event) => this.onChange(event.target.id, event.target.value)}
        />,
      }, {
        label: "Email",
        key: "conseil_syndical_mail",
        flex: "75%",
        input: <Input
          type="email"
          placeholder="Email"
          onChange={(event) => this.onChange(event.target.id, event.target.value)}
        />,
        options: {
          validateTrigger: "onBlur",
          rules: [
            {type: "email", message: "Format incorrect"}
          ],
        },
      },
    ];

    return <CpEditForm
      formRef={formRef}
      fields={fields}
      values={values}
      setRequiredFields={setRequiredFields}
      onFieldsChange={onFieldsChange}
    />;
  }
}

export default (props) => <FormCoproEdit {...props}/>;
