import _ from "lodash";
import {isNullOrUndefined} from "./index";

export function modifierDonneesCompteurLegacyPourPut(donnees) {
    let nouvelObjet = donnees;
//suivant le payload qu'on récupère, on peut avoir un objet ou directement l'id pour la copro et le fournisseur
    nouvelObjet.copropriete = donnees?.copropriete?.id ? donnees?.copropriete?.id : donnees.copropriete;
    nouvelObjet.fournisseur = donnees?.fournisseur?.id ? donnees?.fournisseur?.id : donnees.fournisseur;


    return _.omit(nouvelObjet, [
        "filtre_adresse_copro",
        "filtre_nom_fournisseur",
        "date_creation_transaction",
        "documents",
        "consommation",
        "comment",
        "commentaire_date",
        "commentaire_auteur",
        "comment_author",
        "comment_date",
        "consumption_date",
        "consumption_total",
        "copro_address",
        "copro_name",
        "countDocuments",
        "date_end",
        "fichier_contrat",
        "id",
        "label",
        "logo",
        "name",
        "number",
        "tendance_marche",
        "transaction_id",
    ]);
}

export function modifierDonneesCoproLegacyPourPut(donnees) {
    let nouvelObjet = { ...donnees };
    if(typeof donnees.commentaire === "object"){
        nouvelObjet.commentaire = donnees.commentaire?.commentaire
        nouvelObjet.commentaire_auteur = donnees.commentaire?.auteur
        nouvelObjet.commentaire_date = donnees.commentaire?.date
    }
    return _.omit(nouvelObjet, [
        "id",
        "adresse",
        "echeances_compteurs",
        "syndicat",
        "total_compteurs",
    ]);
}

export function modifierDonneesCompteurLegacyModalEdition(donneesAEnvoyer) {
    if(!isNullOrUndefined(donneesAEnvoyer.commentaire)) {

        donneesAEnvoyer.commentaire = {
            commentaire: donneesAEnvoyer.commentaire,
            auteur: donneesAEnvoyer.auteur,
            date: donneesAEnvoyer.date,
        };
    }
    return _.omit(donneesAEnvoyer, [
    "comment",
    "fichier_contrat",
    "filtre_adresse_copro",
    "filtre_nom_fournisseur",
    "id",
    "tendance_marche",
    "transaction_id",
    "date_creation_transaction"
]);
}

export function modifierDonneesCompteurLegacyDependance(donneesRecues) {
    let nouvelObjet = {};
    if(!isNullOrUndefined(donneesRecues)){
    nouvelObjet = donneesRecues ;
    nouvelObjet.commentaire = {
        commentaire: donneesRecues?.comment,
        auteur: donneesRecues?.comment_author,
        date: donneesRecues?.comment_date,
    };

    //y a pas copropriete?.id si ça vient de managercoprolist
    nouvelObjet.copropriete = donneesRecues?.copropriete.id;
    nouvelObjet.fournisseur = donneesRecues?.fournisseur.id;
}
    return nouvelObjet;
}

export function formatageCommentaire(typeObjet, donneesAFormater){
    if(isNullOrUndefined(donneesAFormater)) return null;
    if(typeObjet === 'copro'){
        return {
            commentaire: donneesAFormater.commentaire,
            auteur: donneesAFormater.commentaire_auteur,
            date: donneesAFormater.commentaire_date,
        }
    }
    return {
        commentaire: donneesAFormater.comment,
        auteur: donneesAFormater.comment_author,
        date: donneesAFormater.comment_date,
    }
}
