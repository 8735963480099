import Pluralize from "./Pluralize";
import SiretValidation from "./SiretValidation";
import Capitalize from "./Capitalize";
import {modifierDonneesCompteurLegacyPourPut, modifierDonneesCoproLegacyPourPut, modifierDonneesCompteurLegacyModalEdition} from "./FormatagesDonnees";

function isNullOrUndefined(value){
      return value === undefined || value === null;
    }

export {Pluralize, SiretValidation, Capitalize, modifierDonneesCompteurLegacyPourPut, modifierDonneesCoproLegacyPourPut, isNullOrUndefined, modifierDonneesCompteurLegacyModalEdition};
