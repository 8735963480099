import React, {useState} from "react";
import {withRouter} from "react-router-dom";
import {Modal} from "antd";
import CpButton from "../buttons/CpButton";
import {CpIconAdd} from "../CpIcon";
import lang from "../../../lang/fr";
import CreationCompteurSimple from "../../compteur/CreationCompteurSimple";

function ModaleCreationCompteurSimple() {
  const [isVisible, setIsVisible] = useState(false);

  const showModal = () => {
    setIsVisible(true);
  };
  const handleCancel = () => {
    setIsVisible(false);
  };
  return (
    <>
      <CpButton
        size="large"
        type="secondary"
        className="CpTitleButton"
        onClick={showModal}
        icon={<CpIconAdd size={15}/>}
        style={{
          float: "right",
          minWidth: 110
        }}>COMPTEUR
      </CpButton>
      <Modal title={lang.compteur.compteur} buttonProps={{
        icon: <CpIconAdd/>,
        cpsize: "small",
        type: "secondary",
        className: "ant-btn show-btn",
        style: {float: "right", minWidth: 110},
      }} footer={null} visible={isVisible}
             onCancel={handleCancel}>
        <CreationCompteurSimple onCancel={handleCancel} visible={isVisible}/>
      </Modal>
    </>
  );
}

export default withRouter(ModaleCreationCompteurSimple);
