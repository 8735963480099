import React from "react";
import {Select} from "antd";
import PropTypes from "prop-types";
import {inject, observer} from "mobx-react";
import {withRouter} from "react-router-dom";

@inject("dictionary", "list")
@observer
class CpDeadlineFilter extends React.Component {
  constructor(props) {
    super(props);

    this.onSelectDeadline = this.onSelectDeadline.bind(this);
  }

  componentDidMount() {
    const {list, field = "date_end", history} = this.props;
    const {location} = history;
    const queryParams = new URLSearchParams(location.search);

    list.filtersDeadline.field = field;

    if (queryParams.has("date_echeance")) {
      list.filtersDeadline.value = queryParams.get("date_echeance");
    } else {
      list.filtersDeadline.value = null;
    }

  }

  onSelectDeadline = (deadline) => {
    const {list, dictionary, history} = this.props;
    const {location} = history;
    const queryParams = new URLSearchParams(location.search);

    queryParams.delete("date_echeance");

    if (Object.values(dictionary.enums.deadlineTypeEnum).includes(deadline)) {
      list.filtersDeadline.value = deadline;
      queryParams.append("date_echeance", deadline);
    } else {
      list.filtersDeadline.value = null;
    }

    history.replace({
      search: queryParams.toString()
    });
  };

  render() {
    const {dictionary, history} = this.props;
    const {location} = history;
    const queryParams = new URLSearchParams(location.search);

    return <Select
      placeholder={"Filtrer par échéance"}
      onChange={this.onSelectDeadline}
      style={{width: "250px", marginLeft: "5px", marginRight: "5px"}}
      allowClear={true}
      defaultValue={queryParams.has("date_echeance") ? `${queryParams.get("date_echeance")} mois` : null}
    >
      <Select.Option key={0} value={"CLEAR"}>Pas d'échéance</Select.Option>

      {
        dictionary.enums && dictionary.enums.deadlineTypeEnum ?
          Object.values(dictionary.enums.deadlineTypeEnum).
            map((deadline, index) =>
              <Select.Option key={index + 1}
                             value={deadline}>{deadline} mois</Select.Option>,
            ) :
          null
      }
    </Select>;
  }
}

CpDeadlineFilter.propTypes = {
  field: PropTypes.string,
};

export default withRouter(CpDeadlineFilter);
