import React, {useEffect, useState} from "react";
import {withRouter} from "react-router-dom";
import {Layout} from "antd";
import BreadCrumbPE from "../common/BreadCrumbPE";
import MaestroCpSection from "./MaestroCpSection";
import DataService from "../../services/dataService";
import {DataTypeEnum} from "../common/enums/DataTypeEnum";
import CpTitle from "../common/CpTitle";
import CpTypeCompteurFormat from "../common/CpTypeCompteurFormat";

function MaestroFactureForm(props) {
  const idDemande = props.match.params.idDemande;
  const [factureMaestro, setFactureMaestro] = useState();
  const [nomGestionnaire, setNomGestionnaire] = useState(
    props.location?.managerName);
  const [idAgence, setIdAgence] = useState(0);
  const idManager = props.match.params.id;
  const nomLocalStorage = `traitementFacture-${idDemande}`;
  const donneesFormulaireFacturesLocalStorage = Object.hasOwn(localStorage,
    nomLocalStorage) ? JSON.parse(localStorage.getItem(nomLocalStorage)) : null;

  DataService.getById(DataTypeEnum.MANAGER, idManager).then(({data}) => {
    return [
      setNomGestionnaire(data.qualifiedName),
      setIdAgence(data.agency.id)];
  });

  useEffect(() => {
    DataService.getFacture(idDemande).then(({data}) => {
      if (donneesFormulaireFacturesLocalStorage) {
        miseAJourInfosFactures(data,
          donneesFormulaireFacturesLocalStorage.factures);
        miseAJourInfosCopros(data,
          donneesFormulaireFacturesLocalStorage.copros);
      }
      setFactureMaestro(data);
    });
  }, []);

  const comparaisonFacture = (facture, idFacture, valeurFacture) => {
    if (facture.id === idFacture) {
      return {...facture, ...valeurFacture};
    }
    return facture;
  }
  const miseAJourInfosFactures = (
    donneesRecuperees, localStorageObjetFactures) => {
    for (const [idFacture, valeurFacture] of Object.entries(
      localStorageObjetFactures)) {
      // MAJ des infos du paramètre factures des données récupérés
      donneesRecuperees.factures = donneesRecuperees.factures.map((facture) => comparaisonFacture(facture, idFacture, valeurFacture));
      // MAJ des infos du paramètre factures des copros des données récupérés
      donneesRecuperees.copros = donneesRecuperees.copros.map((copro) => {
        if (copro.factures?.length > 0) {
          copro.factures = copro.factures.map((facture) => comparaisonFacture(facture, idFacture, valeurFacture));
        }
        return copro;
      });
    }
    return donneesRecuperees;
  };

  const miseAJourInfosCopros = (donneesRecuperees, localStorageObjetCopros) => {
    let nouvellesDonneesCopros = donneesRecuperees.copros;
    let nouvellesDonneesFactures = donneesRecuperees.factures;
    for (const [idFactureCopro, valeurCopro] of Object.entries(
      localStorageObjetCopros)) {
      const factureCorrespondante = (copro) =>
        copro?.factures.find((factureCopro) =>
          factureCopro.id === idFactureCopro
        )
      const coproCorrespondante = nouvellesDonneesCopros.find((copro)=> factureCorrespondante(copro))
      if(coproCorrespondante) {
        nouvellesDonneesCopros = nouvellesDonneesCopros.map((copro)=> {
          const factureTrouvee = factureCorrespondante(copro);
          if(factureTrouvee) {
            return {...copro, ...valeurCopro}
          } else {
            return copro;
          }
        })
      } else {
        nouvellesDonneesFactures = nouvellesDonneesFactures.map((facture) => comparaisonFacture(facture, idFactureCopro, valeurCopro));
      }
    }
    donneesRecuperees.factures = nouvellesDonneesFactures;
    donneesRecuperees.copros = nouvellesDonneesCopros;
  };

  const listeNavigation = [
    {
      titre: `Agences`,
      href: "/agency",
    },
    {
      titre: "Gestionnaires",
      href: `/agency/${idAgence}/copros`,
    },
    {
      titre: nomGestionnaire,
      href: `/manager/${idManager}/copros`,
    },
    {
      titre: `Maestro`,
      href: `/gestionnaire/${idManager}/maestro`,
    },
    {
      titre: `Traitement de demandes`,
    },
  ];

  const demandeVide = <CpTitle level={4}
                               style={{
                                 margin: "32px auto",
                                 fontSize: "30",
                                 fontWeight: "bold",
                                 marginLeft: "35%",
                               }}>
    <CpTypeCompteurFormat style={{fontSize: 15}}/>Demande vide
  </CpTitle>;

  const demandeEnCours = <CpTitle level={4}
                                  style={{
                                    margin: "32px auto",
                                    fontSize: "30",
                                    fontWeight: "bold",
                                  }}>
    <CpTypeCompteurFormat style={{fontSize: 15}}/>Demande en cours de traitement
    par Maestro
  </CpTitle>;

  const factures = () => {
    if (factureMaestro === undefined) {
      return <></>;
    }
    if (factureMaestro.date_reception === null) {
      return demandeEnCours;
    }
    return factureMaestro.copros.length > 0 || factureMaestro.factures.length >
    0 ?
      <MaestroCpSection
        factureMaestro={factureMaestro}
        nomLocalStorage={nomLocalStorage}
        donneesFormulaireFacturesLocalStorage={donneesFormulaireFacturesLocalStorage}
      /> : demandeVide;
  };

  return (
    <Layout>
      <BreadCrumbPE listeNavigation={listeNavigation}/>
      {factures()}
    </Layout>
  );
}

export default withRouter(MaestroFactureForm);
