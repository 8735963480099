import React from "react";
import PropTypes from "prop-types";
import {Select} from "antd";
import {inject, observer} from "mobx-react";
import {CpIconSearch} from "../CpIcon";
import styled from "styled-components";
import lang from "../../../lang/fr";
import {withRouter} from "react-router-dom";

const {Option} = Select;

const StyledSelect = styled.div`
    position:relative;
    display:inline-block;
    text-align:right;
    .ant-select-selector{
        position:absolute;
        text-align:left ;
        width:100%;
        top:0;
        left:0;
    }
`;

@inject("list")
@observer
class CpListFilter extends React.Component {

  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    const {list, fields, name, history} = this.props;
    const {location} = history;
    const queryParams = new URLSearchParams(location.search);
    const filtres = JSON.parse(queryParams.get("filtres"));

    list.filterFields[name] = fields;

    list.setFilters(filtres, name);
  }

  handleChange = value => {
    const {list, name, history} = this.props;
    const queryParams = new URLSearchParams();

    list.setFilters(value, name);

    queryParams.delete("filtres");
    if (value && value.length > 0) {
      queryParams.append("filtres", JSON.stringify(value));
    }

    history.replace({
      search: queryParams.toString()
    });
  };

  render() {
    const {list, name, placeholder, optionList} = this.props;

    return (
      <StyledSelect>
        <Select
          className="filter"
          mode="tags"
          style={{width: 180, margin: "0 10px", verticalAlign: "top"}}
          placeholder={
            <span style={{color: "grey"}}>
                            <CpIconSearch
                              style={{marginRight: "10px", fontSize: "20px"}}/>
              {placeholder ? placeholder.toUpperCase() : lang.global.filtrer.toUpperCase()}
                        </span>
          }
          onChange={this.handleChange}
          value={list.getFilters()[name] ? list.getFilters()[name] : undefined}
          notFoundContent={null}
        >
          {
            optionList == null ? null : optionList.map(c => {
              return <Option key={c} value={c}>{c}</Option>;
            })
          }
        </Select>
      </StyledSelect>
    );
  }
}

CpListFilter.defaultProps = {
  name: "default",
};
CpListFilter.propTypes = {
  fields: PropTypes.array.isRequired,
  name: PropTypes.string,
};

export default withRouter(CpListFilter);
